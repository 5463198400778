import React,{useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Intro from '../components/Home/Intro'
import FreeShipping from '../components/Home/FreeShipping'
import Categories from '../components/Home/Categories'
import AdOne from '../components/Home/AdOne'
import Services from '../components/Home/Services'
import ProductHomePage from '../components/Home/ProductHomePage'
// import KidsCollection from '../components/Home/KidsCollection'
import { getAllProductsWithFilter, getAllProducts } from "../redux/actions/productAction";

const Home = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [bestSellerProducts, setBestSellerProducts] = useState([]);

  const allProductsData = useSelector(
    (state) => state.productReducer.products
  );

  const bestSellerProductsData = useSelector(
    (state) => state.productReducer.productsFilter
  );

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(getAllProducts(`limit=${200}`))
  },[])


  useEffect(()=>{
    dispatch(getAllProductsWithFilter(`bestSeller=${true}`))
  },[])

  useEffect(()=>{
    if(allProductsData){
      if(allProductsData.status === 200){
        if(allProductsData.data){
          setAllProducts(allProductsData.data.data)
        }
      }
    }
  },[allProductsData])

  useEffect(()=>{
    if(bestSellerProductsData){
      if(bestSellerProductsData.status === 200){
        if(bestSellerProductsData.data){
          setBestSellerProducts(bestSellerProductsData.data.data)
        }
      }
    }
  },[bestSellerProductsData])

  
  const pantsList = [
    "664632b824be2fd342d3834b",
    "664631b624be2fd342d3832c",
    "6646315424be2fd342d382f5",
    "6646346824be2fd342d383a2",
    "6646381e24be2fd342d384de"
  ]

  const tshirtList = [
    "667a134f1b9a26c51f96a18b",
    "667a13b31b9a26c51f96a198",
    "667a14051b9a26c51f96a1a5",
    "66315d63ccfa6e49eecc9310",
    "66315d0fccfa6e49eecc92e0",
    "66315e87ccfa6e49eecc96e1"
  ]

  const pants = allProducts.filter(product=> pantsList.includes(product._id))
  const tshirt = allProducts.filter(product=> tshirtList.includes(product._id))

console.log(tshirt)
  return (
    <>
        <Intro/>
        <FreeShipping/>
        <AdOne/>
        <ProductHomePage title="المنتجات الأكثر مبيعاً" products={bestSellerProducts}/>
        <Categories/>
        <ProductHomePage title="كوليكشن التيشرت" products={tshirt}/>
        <ProductHomePage title="كوليكشن البنطلون" products={pants}/>
        {/* <KidsCollection/> */}
        <Services/>
    </>
  )
}

export default Home